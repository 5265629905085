/* eslint-disable no-magic-numbers */
window.conditionals = (function() {
  'use strict';

  function cssExists(filename) {
    let links = document.getElementsByTagName('link');

    for (let idx = 0; idx < links.length; idx = idx + 1) {
      if (links[idx].href.includes(filename)) {
        return true;
      }
    }
    return false;
  }

  function getStylingPath() {
    let scriptsLocation = '/scripts/',
        cssLocation = '';
    const scripts = document.getElementsByTagName('script');

    for (let idx = 0; idx <= scripts.length; idx = idx + 1) {
      if (scripts[idx].src.indexOf('conditionals.min.js') > -1) {
        scriptsLocation = scripts[idx].src.replace('conditionals.min.js', '');
        break;
      }
    }
    cssLocation = scriptsLocation.replace('/scripts/', '/assets/css/');
    return cssLocation;
  }

  function appendPreload(filename) {
    let styleSheet = document.createElement('link');

    styleSheet.rel = 'preload';
    /* eslint-disable id-length */
    styleSheet.as = 'style';
    /* eslint-enable id-length */
    styleSheet.href = filename;
    document.head.appendChild(styleSheet);
  }

  function appendStyling(filename) {
    let styleSheet = document.createElement('link');

    styleSheet.rel = 'stylesheet';
    styleSheet.type = 'text/css';
    styleSheet.href = filename;
    document.head.appendChild(styleSheet);
  }

  function addCss(cssPath, filename) {
    if (cssExists(filename)) {
      return;
    }
    appendPreload(cssPath + filename);
    appendStyling(cssPath + filename);
  }

  function loadConditionalStyling() {
    let cssPath = getStylingPath();

    if (window.matchMedia('(min-width: 692px)').matches) {
      addCss(cssPath, 'base-small.css');
    }
    if (window.matchMedia('(min-width: 997px)').matches) {
      addCss(cssPath, 'base-medium.css');
    }
    if (window.matchMedia('(min-width: 1176px)').matches) {
      addCss(cssPath, 'base-large.css');
    }
  }

  return {
    init: () => {
      loadConditionalStyling();
      window.addEventListener('resize', loadConditionalStyling);
    }
  };
})();

window.conditionals.init();
